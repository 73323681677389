import React from 'react'
import Layout from '../components/layout'
import SEO from '../components/seo'
import BlockContent from '../components/block-content'

export default function CustomPage(props) {
  const { name, pageContent } = props?.pageContext

  return (
    <Layout>
      <SEO title={name} />
      <BlockContent blocks={pageContent} />
    </Layout>
  )
}
